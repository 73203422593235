var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "media",
      class: { sequential: _vm.message.sequential, unread: !_vm.isRead },
    },
    [
      !_vm.message.sequential
        ? _c(
            "a",
            {
              directives: [
                {
                  name: "profile",
                  rawName: "v-profile",
                  value: _vm.message.user.id,
                  expression: "message.user.id",
                },
              ],
              staticClass: "i-45 me-2 d-none d-sm-block",
            },
            [
              _c("vue-avatar", {
                staticClass: "mw-100 img-thumbnail media-object",
                attrs: {
                  photo: _vm.message.user.photo,
                  name: _vm.message.user.name,
                  initials: _vm.message.user.initials,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "media-body" },
        [
          !_vm.message.sequential
            ? [
                _c(
                  "small",
                  { staticClass: "float-end text-muted" },
                  [
                    _c("vue-timeago", {
                      attrs: { datetime: _vm.message.created_at },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "h3",
                  [
                    _vm.clickableText
                      ? _c("vue-username", {
                          attrs: {
                            user: _vm.message.user,
                            href: "/User/Pm/Show/" + _vm.message.id,
                          },
                        })
                      : _c("vue-username", {
                          attrs: { user: _vm.message.user },
                        }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-delete float-end text-danger",
              attrs: { href: "javascript:", title: "Usuń" },
              on: { click: _vm.deleteMessage },
            },
            [_c("i", { staticClass: "fas fa-trash-can" })]
          ),
          _vm._v(" "),
          _vm.clickableText
            ? _c(
                "a",
                {
                  staticClass: "excerpt",
                  attrs: { href: "/User/Pm/Show/" + _vm.message.id },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.message.excerpt
                        ? _vm.message.excerpt
                        : "(kliknij, aby przeczytać)"
                    )
                  ),
                ]
              )
            : _c("div", {
                staticClass: "pm-text",
                domProps: { innerHTML: _vm._s(_vm.message.text) },
              }),
          _vm._v(" "),
          _vm.last && _vm.message.folder === _vm.SENTBOX && _vm.message.read_at
            ? _c(
                "small",
                { staticClass: "text-muted" },
                [
                  _c("i", { staticClass: "fas fa-check" }),
                  _vm._v("\n      Przeczytano,\n      "),
                  _c("vue-timeago", {
                    attrs: { datetime: _vm.message.read_at },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }